<template>
  <div>
    <NewTicketDialog
      :showDialog="showDialog"
      @closeDialog="closeDialog"
      @updateNewTicketAdded="updateNewTicketAdded"
      @showDialogChange="showDialogChange"
      :client-id="clientId"
    />
    <SIngleTicketDialog
      :showDialog="showSingleTicketDialog"
      @closeDialog="closeDialogSingle"
      :ticket="selectedTicket"
      @showDialogChange="showDialogChange"
      :key="showSingleTicketKey"
      :client-id="clientId"
      :isAttorney="false"
    />
    <v-row justify="space-between">
      <v-col cols="12" v-if="newTicketAdded">
        <v-alert class="text-center" type="warning"
          >Your ticket is received but may take up to 5 minutes to see it listed below
        </v-alert>
      </v-col>
      <v-col cols="auto">
        <h2>Support tickets</h2>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn color="primary" @click="openNewTicketDialog">New Ticket</v-btn>
      </v-col>
    </v-row>
    <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <v-expansion-panels v-else :value="0" class="mt-6">
      <h3 v-if="Object.entries(tickets).length === 0" style="color: #6c757d">
        There are no tickets for this client currently.
      </h3>
      <v-expansion-panel v-for="type in Object.entries(tickets)">
        <v-expansion-panel-header style="background-color: #f9f9f9; height: 45px" ripple>
          <v-row no-gutters class="text--secondary">
            <v-col>
              {{ type[0].charAt(0).toUpperCase() + type[0].slice(1).toLowerCase() }} Ticket
            </v-col>
            <v-col v-if="extended"> Client</v-col>
            <v-col> Age</v-col>
            <v-col> Subject</v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item class="text-center" v-if="tickets === {}">
              There are no {{ type[0] }} tickets for this client
            </v-list-item>
            <v-list-item v-else v-for="ticket in type[1]" :key="ticket.id" ripple link>
              <v-row
                @click="openTicketDialog(ticket)"
                class="py-2"
                justify="space-around"
                no-gutters
              >
                <v-col>
                  {{ ticket.id }}
                </v-col>
                <v-col>
                  {{ ticket.age }}
                </v-col>
                <v-col>
                  {{ ticket.subject }}
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import clientsService from "@/services/clients.service";
import NewTicketDialog from "@/views/Client/NewTicketDialog.vue";
import SIngleTicketDialog from "@/components/Client/SIngleTicketDialog.vue";

export default {
  name: "ClientSupportTickets",
  components: { SIngleTicketDialog, NewTicketDialog },
  data: () => ({
    clientId: null,
    tickets: {},
    open: [],
    closed: [],
    showDialog: false,
    showSingleTicketDialog: false,
    dialogKey: 0,
    showSingleTicketKey: 0,
    loading: false,
    today: new Date(),
    selectedTicket: {},
    newTicketAdded: false,
  }),
  methods: {
    openTicketDialog(item) {
      this.selectedTicket = item;
      this.showSingleTicketDialog = true;
      this.showSingleTicketKey++;
    },
    updateNewTicketAdded() {
      this.newTicketAdded = true;
    },
    showDialogChange(x) {
      this.showDialog = x;
    },
    closeDialog() {
      this.showDialog = false;
      this.getZendeskTickets();
    },
    closeDialogSingle() {
      this.showSingleTicketDialog = false;
    },
    openNewTicketDialog() {
      this.showDialog = true;
      this.dialogKey++;
    },
    getZendeskTickets() {
      this.loading = true;
      return clientsService
        .getZendeskTickets(this.clientId)
        .then((data) => {
          this.tickets = data;
        })
        .catch((error) => {
          this.$func.popMessage(`${error.response.data.error}`, "fail");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.clientId = this.$route.params.clientId;
    this.getZendeskTickets();
  },
  computed: {
    extended() {
      return this.type === "extended";
    },
  },
};
</script>
